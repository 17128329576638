.carousel_item_class {
    height: 100%;
}

.carousel_container {
    height: 100%;
}

.sliderClass {
    height: 100%;
}