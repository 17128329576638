@font-face {
  font-family: 'Montserrat';
  font-weight: 100 400 700;
  src: local('Montserrat'), url(./static/fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: pirulen;
  src: local('Montserrat'), url(./static/fonts/pirulen.ttf) format('truetype');
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overview-component-title {
  font-size: 1.425rem;
  color: #2196f3;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'montserrat';
  transition: background-color 696969s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

a img {
  border-radius: 0;
}

html {
  scroll-behavior: smooth;
}
