@keyframes fadeIn {  
    from {  
        opacity:0;  
    }  
 
    to {  
        opacity:1;  
    }  
 }

.fadeOut {
    opacity: 0;
    pointer-events: none;
}

.fadeIn {
    opacity: 0;
    animation: fadeIn 0.2s ease-in; 
    animation-fill-mode: forwards;
    pointer-events: all;
}


