.topbar {
    flex-grow: 1;
    z-index: 9999 !important;
    box-shadow: none !important;
}

.drawerContainer {
    width: 100vw;
    padding: 0px;
    height: 100vh;
    background-color: rgb(10, 10, 10);
}

.divider {
    background-color: rgb(50, 50, 50) !important;
    color: white;
}

.nestedList {
    padding-left: 25px;
}

a {
    color: inherit;
    text-decoration: none;
}


.inverseReveal {
    position: relative;
    transform: translateY(-150px);
    opacity: 0;
    transition: 0.3s opacity ease, 0.3s transform ease;
    height: 0;
    width: 0;
}

.inverseReveal.active {
    transform: translateY(0);
    opacity: 1;

    height: 100%;
    width: auto;
}