.uiContainer {
    border-radius: 5px;
    width: 100%;
    padding: 3vw;
    background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(15, 15, 15, 1) 48%);
}

body::-webkit-scrollbar {
    display: none;
}

.reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 0.3s opacity ease, 0.3s transform ease;
    height: 0;
    width: 0;
    overflow: hidden;
}

.reveal.active {
    height: inherit;
    width: inherit;
    transform: translateY(0);
    opacity: 1;
    height: 100%;

}